import { FC, useState } from "react";
import { Params, useParams, useLocation, Navigate } from "react-router-dom";
import { PaymentLinkPayData, SimpleErrorData } from "app/types";
import { messages } from "app/i18n";
import { useIntl } from "react-intl";
import { UnexpectedErrorState } from "app/components";
import { useEffectOnce } from "react-use";
import { paymentLinksApiUrl } from "app/constants";
import {
  ExpectedErrorState,
  StateWithFooter,
} from "@mollie/revenue-collection-components";

enum Error {
  BadResponse = "Bad response from server",
  NoSuitablePaymentMethods = "No suitable payment methods found for the given payment link",
}

export const PaymentLinkPaymentPage: FC = () => {
  const intl = useIntl();
  const [paymentLinkPayData, setPaymentLinkPayData] =
    useState<PaymentLinkPayData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const params: Params<string> = useParams();
  const {
    state,
  }: {
    state: {
      customerToken: string | null;
    };
  } = useLocation();

  useEffectOnce(() => {
    const paymentLinkHash = params.paymentLinkHash?.replace(/^pl_/, "");

    if (state?.customerToken !== undefined) {
      const apiUrl = new URL(
        `api/bff/v1/payment-links/${paymentLinkHash}/pay`,
        paymentLinksApiUrl,
      );
  
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({ customerToken: state.customerToken }),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 422) {
              return response.json().then((data: SimpleErrorData) => {
                if (Object.values(Error).includes(data.error as Error)) {
                  return setError(data.error as Error);
                }
              });
            }
  
            return setError(Error.BadResponse);
          }
  
          return response.json().then((data: PaymentLinkPayData) => {
            setPaymentLinkPayData(data);
          });
        })
        .catch(() => {
          return setError(Error.BadResponse);
        });
    }
  });

  if (state?.customerToken === undefined) {
    return (
      <Navigate
        to={`/${params.lang}/payment/${params.paymentLinkHash}`}
        replace={true}
      />
    );
  }

  if (error === Error.BadResponse) {
    return <UnexpectedErrorState />;
  }

  if (error) {
    const errorKey = Object.keys(Error).find(
      (key) => Error[key as keyof typeof Error] === error,
    );

    return (
      <ExpectedErrorState
        error={intl.formatMessage(
          messages[
            `paymentLinkPaymentError${errorKey}` as keyof typeof messages
          ],
        )}
      />
    );
  }

  if (!paymentLinkPayData) {
    return (
      <StateWithFooter
        title={intl.formatMessage(messages.paymentLinkLandingLoadingTitle)}
        variant="loading"
        description={intl.formatMessage(
          messages.paymentLinkLandingLoadingMessage,
        )}
      />
    );
  }

  // deepcode ignore OR: this URL is supplied by the backend
  window.location.replace(paymentLinkPayData.redirectUrl);
};
