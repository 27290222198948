// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  'invoice-ar.invoice-landing-loading-message':
    'Die Zahlungsoptionen für Ihre Rechnungen werden in Kürze angezeigt.',
  'invoice-ar.invoice-landing-loading-title': 'Laden...',
  'invoice-ar.invoice-not-found-error': 'Rechnung nicht gefunden',
  'invoice-ar.invoice-paid-with-invoice-number': 'Die Rechnung {invoiceNumber} wurde bezahlt',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable': 'Rechnung ist nicht zahlbar',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-description':
    'Bitte informieren Sie sich bei Ihrem Lieferanten nach dem Status dieser Rechnung.',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-with-invoice-number':
    'Rechnung {invoiceNumber} ist nicht zahlbar',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available':
    'Online-Zahlung nicht unterstützt',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available-description':
    'Online-Zahlung ist für diese Rechnung nicht möglich. Bitte bezahlen Sie die Rechnung per Banküberweisung.',
  'invoice-ar.invoice-unexpected-error':
    'Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es später noch einmal oder kontaktieren Sie den Support',
  'payment-links.payment-link-details-form-amount-label': 'Betrag',
  'payment-links.payment-link-details-form-continue-to-checkout-button': 'Weiter zu Checkout',
  'payment-links.payment-link-details-form-currency-label': 'Währung',
  'payment-links.payment-link-details-form-description-helper-text':
    'Ein Vermerk für die Zahlung, die Sie senden',
  'payment-links.payment-link-details-form-description-label': 'Beschreibung',
  'payment-links.payment-link-details-form-disclaimer':
    'Durch die Zahlung ermächtigen Sie {organisation}, Sie gemäß ihren Bedingungen für zukünftige Zahlungen zu belasten. Sie können Ihr Mandat jederzeit abbrechen.',
  'payment-links.payment-link-details-form-email-label': 'E-Mail',
  'payment-links.payment-link-details-form-error-amount-positive': 'Betrag muss positiv sein',
  'payment-links.payment-link-details-form-error-invalid-email-address': 'Ungültige E-Mail Adresse',
  'payment-links.payment-link-details-form-error-invalid-name':
    'Der Name muss zwischen 5 und 255 Zeichen lang sein.',
  'payment-links.payment-link-details-form-error-minimum-amount':
    'Der Mindestbetrag beträgt {amount}',
  'payment-links.payment-link-details-form-error-payment-amount-too-high': 'Betrag ist zu hoch',
  'payment-links.payment-link-details-form-error-payment-amount-too-low': 'Betrag ist zu niedrig',
  'payment-links.payment-link-details-form-name-label': 'Name',
  'payment-links.payment-link-expired': 'Dieser Zahlungslink ist abgelaufen',
  'payment-links.payment-link-expired-description':
    'Kontaktieren Sie {profileName}, um einen neuen Link anzufordern',
  'payment-links.payment-link-landing-loading-message':
    'Ihre Zahlungslink-Zahlungsoptionen werden in Kürze erscheinen.',
  'payment-links.payment-link-landing-loading-title': 'Laden...',
  'payment-links.payment-link-not-found': 'Zahlungslink nicht gefunden',
  'payment-links.payment-link-paid': 'Dieser Zahlungslink wurde bezahlt',
  'payment-links.payment-link-payment-error-no-suitable-payment-methods':
    'Keine passende Zahlungsmethode für diesen Zahlungslink gefunden. Wenden Sie sich an den Anbieter Ihres Links, um dieses Problem zu lösen.',
  'payment-links.payment-link-payment-loading-message':
    'Der Status Ihrer Zahlung wird in Kürze angezeigt.',
  'payment-links.payment-link-payment-paid-title':
    'Ihre {amount} Zahlung an {profileName} war erfolgreich',
  'payment-links.payment-link-payment-pending-description':
    'Wir warten auf den Status Ihrer Zahlung. Wir werden Sie informieren, sobald die Zahlung eingegangen ist.',
  'payment-links.payment-link-payment-pending-title':
    'Ihre {amount} Zahlung an {profileName} steht noch aus',
  'payment-links.payment-link-unexpected-error':
    'Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es später noch einmal oder kontaktieren Sie den Support',
};
