import { defineMessages } from "react-intl";

export const messages = defineMessages({
  paymentLinkLandingLoadingTitle: {
    id: "payment-links.payment-link-landing-loading-title",
    defaultMessage: "Loading...",
  },
  paymentLinkLandingLoadingMessage: {
    id: "payment-links.payment-link-landing-loading-message",
    defaultMessage: "Your Payment Link payment options will appear soon.",
  },
  paymentLinkNotFound: {
    id: "payment-links.payment-link-not-found",
    defaultMessage: "Payment link not found",
  },
  paymentLinkExpired: {
    id: "payment-links.payment-link-expired",
    defaultMessage: "This payment link has expired",
  },
  paymentLinkUnexpectedError: {
    id: "payment-links.payment-link-unexpected-error",
    defaultMessage:
      "An unexpected error occurred, please try again later or contact support",
  },
  paymentLinkExpiredDescription: {
    id: "payment-links.payment-link-expired-description",
    defaultMessage: "Contact {profileName} to request a new link",
  },
  paymentLinkPaid: {
    id: "payment-links.payment-link-paid",
    defaultMessage: "This payment link has been paid",
  },
  paymentLinkPaymentLoadingMessage: {
    id: "payment-links.payment-link-payment-loading-message",
    defaultMessage: "Your payment status will appear soon.",
  },
  paymentLinkPaymentPaidTitle: {
    id: "payment-links.payment-link-payment-paid-title",
    defaultMessage: "Your {amount} payment to {profileName} succeeded",
  },
  paymentLinkPaymentPendingTitle: {
    id: "payment-links.payment-link-payment-pending-title",
    defaultMessage: "Your {amount} payment to {profileName} is pending",
  },
  paymentLinkPaymentPendingDescription: {
    id: "payment-links.payment-link-payment-pending-description",
    defaultMessage:
      "We’re awaiting your payment status. We’ll inform you once the payment has cleared.",
  },
  paymentLinkPaymentErrorNoSuitablePaymentMethods: {
    id: "payment-links.payment-link-payment-error-no-suitable-payment-methods",
    defaultMessage:
      "No suitable payment methods found for this payment link. Contact the supplier of your link to resolve this problem.",
  },

  // Payment details form
  paymentLinkDetailsFormAmountLabel: {
    id: "payment-links.payment-link-details-form-amount-label",
    defaultMessage: "Amount",
  },
  paymentLinkDetailsFormCurrencyLabel: {
    id: "payment-links.payment-link-details-form-currency-label",
    defaultMessage: "Currency",
  },
  paymentLinkDetailsFormDescriptionLabel: {
    id: "payment-links.payment-link-details-form-description-label",
    defaultMessage: "Description",
  },
  paymentLinkDetailsFormDescriptionHelperText: {
    id: "payment-links.payment-link-details-form-description-helper-text",
    defaultMessage: "A note for the payment you’re sending",
  },
  paymentLinkDetailsFormContinueToCheckoutButton: {
    id: "payment-links.payment-link-details-form-continue-to-checkout-button",
    defaultMessage: "Continue to checkout",
  },
  paymentLinkDetailsFormDisclaimer: {
    id: "payment-links.payment-link-details-form-disclaimer",
    defaultMessage: "By paying, you allow {organisation} to charge you for future payments in accordance to their terms. You can always cancel your mandate.",
  },
  paymentLinkDetailsFormErrorAmountPositive: {
    id: "payment-links.payment-link-details-form-error-amount-positive",
    defaultMessage: "Amount must be positive",
  },
  paymentLinkDetailsFormErrorPaymentAmountTooHigh: {
    id: "payment-links.payment-link-details-form-error-payment-amount-too-high",
    defaultMessage: "Amount is too high",
  },
  paymentLinkDetailsFormErrorPaymentAmountTooLow: {
    id: "payment-links.payment-link-details-form-error-payment-amount-too-low",
    defaultMessage: "Amount is too low",
  },
  paymentLinkDetailsFormNameLabel: {
    id: "payment-links.payment-link-details-form-name-label",
    defaultMessage: "Name",
  },
  paymentLinkDetailsFormEmailLabel: {
    id: "payment-links.payment-link-details-form-email-label",
    defaultMessage: "Email",
  },
  paymentLinkDetailsFormErrorInvalidEmailAddress: {
    id: "payment-links.payment-link-details-form-error-invalid-email-address",
    defaultMessage: "Invalid email address",
  },
  paymentLinkDetailsFormErrorInvalidName: {
    id: "payment-links.payment-link-details-form-error-invalid-name",
    defaultMessage: "Name must be between 5 and 255 characters",
  },
  paymentLinkDetailsFormErrorMinimumAmount: {
    id: "payment-links.payment-link-details-form-error-minimum-amount",
    defaultMessage: "The minimum amount is {amount}",
  }
});
