// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  'invoice-ar.invoice-landing-loading-message':
    'Le opzioni di pagamento della fattura appariranno tra poco.',
  'invoice-ar.invoice-landing-loading-title': 'Caricamento...',
  'invoice-ar.invoice-not-found-error': 'Fattura non trovata',
  'invoice-ar.invoice-paid-with-invoice-number': 'La fattura {invoiceNumber} è stata pagata',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable': 'La fattura non è pagabile',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-description':
    'Per informazioni sullo stato di questa fattura, contattare il proprio fornitore.',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-with-invoice-number':
    'La fattura {invoiceNumber} non è pagabile',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available':
    'Pagamenti online non supportati',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available-description':
    'Il pagamento online non è supportato per questa fattura. Si prega di pagare la fattura tramite bonifico bancario.',
  'invoice-ar.invoice-unexpected-error':
    "Si è verificato un errore imprevisto, riprovare più tardi o contattare l'assistenza",
  'payment-links.payment-link-details-form-amount-label': 'Importo',
  'payment-links.payment-link-details-form-continue-to-checkout-button': 'Continua con il checkout',
  'payment-links.payment-link-details-form-currency-label': 'Valuta',
  'payment-links.payment-link-details-form-description-helper-text':
    'Una nota per il pagamento che stai inviando',
  'payment-links.payment-link-details-form-description-label': 'Descrizione',
  'payment-links.payment-link-details-form-disclaimer':
    'Pagando autorizzi {organisation} ad addebitarti i pagamenti futuri in conformità con i loro termini. Puoi sempre annullare il tuo mandato.',
  'payment-links.payment-link-details-form-email-label': 'Email',
  'payment-links.payment-link-details-form-error-amount-positive': "L'importo deve essere positivo",
  'payment-links.payment-link-details-form-error-invalid-email-address':
    'Indirizzo e-mail non valido',
  'payment-links.payment-link-details-form-error-invalid-name':
    'Il nome deve essere compreso tra 5 e 255 caratteri',
  'payment-links.payment-link-details-form-error-minimum-amount': "L'importo minimo è {amount}",
  'payment-links.payment-link-details-form-error-payment-amount-too-high': 'Importo troppo elevato',
  'payment-links.payment-link-details-form-error-payment-amount-too-low': 'Importo troppo basso',
  'payment-links.payment-link-details-form-name-label': 'Nome',
  'payment-links.payment-link-expired': 'Questo link di pagamento è scaduto',
  'payment-links.payment-link-expired-description':
    'Contattare {profileName} per richiedere un nuovo link',
  'payment-links.payment-link-landing-loading-message':
    'Le opzioni del Link di pagamento appariranno tra poco.',
  'payment-links.payment-link-landing-loading-title': 'Caricamento...',
  'payment-links.payment-link-not-found': 'Link di pagamento non trovato',
  'payment-links.payment-link-paid': 'Questo link di pagamento è stato pagato',
  'payment-links.payment-link-payment-error-no-suitable-payment-methods':
    'Non sono stati trovati metodi di pagamento adatti per questo link di pagamento. Contatta il fornitore del link per risolvere il problema.',
  'payment-links.payment-link-payment-loading-message': 'Lo stato dei pagamenti apparirà tra poco.',
  'payment-links.payment-link-payment-paid-title':
    'Il pagamento di {amount} a {profileName} è riuscito',
  'payment-links.payment-link-payment-pending-description':
    'Siamo in attesa dello stato del pagamento. Vi informeremo una volta che il pagamento sarà stato effettuato.',
  'payment-links.payment-link-payment-pending-title':
    'Il pagamento di {amount} a {profileName} è in attesa',
  'payment-links.payment-link-unexpected-error':
    "Si è verificato un errore imprevisto, riprovare più tardi o contattare l'assistenza",
};
