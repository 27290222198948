// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  'invoice-ar.invoice-landing-loading-message':
    'Les options de paiement de vos Invoice apparaîtront bientôt.',
  'invoice-ar.invoice-landing-loading-title': 'Chargement...',
  'invoice-ar.invoice-not-found-error': 'Facture non trouvée',
  'invoice-ar.invoice-paid-with-invoice-number': 'La facture {invoiceNumber} est payée',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable': "La Facture n'est pas payable",
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-description':
    'Veuillez contacter votre fournisseur pour connaître le statut de cette facture.',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-with-invoice-number':
    "La Facture {invoiceNumber} n'est pas payable",
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available':
    'Paiements en ligne non pris en charge',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available-description':
    "Le paiement en ligne n'est pas possible pour cette facture. Veuillez régler la facture par virement bancaire.",
  'invoice-ar.invoice-unexpected-error':
    "Une erreur inattendue s'est produite, veuillez réessayer plus tard ou contacter le service d'assistance",
  'payment-links.payment-link-details-form-amount-label': 'Montant',
  'payment-links.payment-link-details-form-continue-to-checkout-button':
    'Continuez vers le checkout',
  'payment-links.payment-link-details-form-currency-label': 'Devise',
  'payment-links.payment-link-details-form-description-helper-text':
    'Une note pour les paiements que vous envoyez',
  'payment-links.payment-link-details-form-description-label': 'Description',
  'payment-links.payment-link-details-form-disclaimer':
    'En payant, vous autorisez {organisation} à vous débiter pour les paiements futurs conformément à leurs conditions. Vous pouvez toujours annuler votre mandat.',
  'payment-links.payment-link-details-form-email-label': 'Email',
  'payment-links.payment-link-details-form-error-amount-positive': 'Le montant doit être positif',
  'payment-links.payment-link-details-form-error-invalid-email-address': 'Email invalide',
  'payment-links.payment-link-details-form-error-invalid-name':
    'Le nom doit être compris entre 5 et 255 caractères',
  'payment-links.payment-link-details-form-error-minimum-amount': 'Le montant minimum est {amount}',
  'payment-links.payment-link-details-form-error-payment-amount-too-high':
    'Le montant est trop élevé',
  'payment-links.payment-link-details-form-error-payment-amount-too-low': 'Le montant est trop bas',
  'payment-links.payment-link-details-form-name-label': 'Nom',
  'payment-links.payment-link-expired': 'Ce lien the paiement a expiré',
  'payment-links.payment-link-expired-description':
    'Contactez {profileName} pour demander un nouveau lien',
  'payment-links.payment-link-landing-loading-message':
    'Les options de paiement de vos Liens de Paiement apparaîtront bientôt.',
  'payment-links.payment-link-landing-loading-title': 'Chargement...',
  'payment-links.payment-link-not-found': "Le lien de paiement n'a pas été trouvé",
  'payment-links.payment-link-paid': 'Ce lien de paiement a été payé',
  'payment-links.payment-link-payment-error-no-suitable-payment-methods':
    "Aucune méthode de paiement appropriée n'a été trouvée pour ce lien de paiement. Contactez le fournisseur de votre lien pour résoudre ce problème.",
  'payment-links.payment-link-payment-loading-message':
    'Le statut de votre paiement apparaîtra bientôt.',
  'payment-links.payment-link-payment-paid-title':
    'Votre paiement {amount} à {profileName} a réussi',
  'payment-links.payment-link-payment-pending-description':
    'Nous attendons le statut de votre paiement. Nous vous informerons dès que le paiement aura été effectué.',
  'payment-links.payment-link-payment-pending-title':
    'Votre paiement {amount} à {profileName} est en cours',
  'payment-links.payment-link-unexpected-error':
    "Une erreur inattendue s'est produite, veuillez réessayer plus tard ou contacter le service d'assistance",
};
