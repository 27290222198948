import { Navigate, Params, useParams } from "react-router-dom";
import { NotFoundState, UnexpectedErrorState } from "app/components";
import { useIntl } from "react-intl";
import { FC, useState } from "react";
import { ClockTime } from "@mollie/ui-assets/icons/utilitarian";
import { messages } from "app/i18n/messages";
import { AutoPayData, PublicPaymentLinkData } from "app/types";
import { useEffectOnce } from "react-use";
import { paymentLinksApiUrl } from "app/constants";
import { StateWithFooter } from "@mollie/revenue-collection-components";

enum Error {
  NotFound = "Payment link not found",
  BadResponse = "Bad response from server",
}

export const PaymentLinkLandingPage: FC = () => {
  const intl = useIntl();
  const [paymentLinkData, setPaymentLinkData] =
    useState<PublicPaymentLinkData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const params: Params<string> = useParams();

  useEffectOnce(() => {
    const apiUrl = new URL(
      `api/bff/v1/payment-links/${params.paymentLinkHash}`,
      paymentLinksApiUrl,
    );

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            return setError(Error.NotFound);
          } else {
            return setError(Error.BadResponse);
          }
        }

        return response.json().then((data: PublicPaymentLinkData) => {
          setPaymentLinkData(data);
        });
      })
      .catch(() => {
        return setError(Error.BadResponse);
      });
  });

  if (error === Error.NotFound) {
    return <NotFoundState />;
  }
  if (error === Error.BadResponse) {
    return <UnexpectedErrorState />;
  }

  if (!paymentLinkData) {
    return (
      <StateWithFooter
        title={intl.formatMessage(messages.paymentLinkLandingLoadingTitle)}
        variant="loading"
        description={intl.formatMessage(
          messages.paymentLinkLandingLoadingMessage,
        )}
      />
    );
  }

  if (paymentLinkData.status === "paid" && !paymentLinkData.isPayable) {
    return (
      <StateWithFooter
        title={intl.formatMessage(messages.paymentLinkPaid)}
        variant="success"
      />
    );
  }

  if (paymentLinkData.expired || !paymentLinkData.isPayable) {
    return (
      <StateWithFooter
        title={intl.formatMessage(messages.paymentLinkExpired)}
        description={intl.formatMessage(
          messages.paymentLinkExpiredDescription,
          {
            profileName: paymentLinkData.profileName,
          },
        )}
        icon={ClockTime}
        variant="empty"
      />
    );
  }

  let autoPayData: AutoPayData | null = null;

  if (paymentLinkData.isVariableAmount) {
    if (params.amount) {
      autoPayData = {
        amount: params.amount,
        message: params.message,
      };
    }
  }

  if (paymentLinkData.sequenceType === "first") {
    return (
      <Navigate
        to={`/${params.lang}/payment/${paymentLinkData.id}/details`}
        replace={true}
        state={{ paymentLinkData: paymentLinkData, autoPayData: autoPayData, customerToken: paymentLinkData.customerToken}}
      />
    );
  }

  if (paymentLinkData.isVariableAmount) {
    return (
      <Navigate
        to={`/${params.lang}/payment/${paymentLinkData.id}/details`}
        replace={true}
        state={{ paymentLinkData: paymentLinkData, autoPayData: autoPayData, customerToken: paymentLinkData.customerToken}}
      />
    );
  }

  return (
    <Navigate
      to={`/${params.lang}/payment/${paymentLinkData.id}/pay`}
      replace={true}
      state={{ customerToken: paymentLinkData.customerToken }}
    />
  );
};
