import { FC, useCallback, useMemo, useState } from "react";
import { Navigate, Params, useLocation, useParams } from "react-router-dom";
import {
  AutoPayData,
  CustomerData,
  CustomerRequestData,
  ErrorData,
  PaymentLinkPayData,
  PaymentLinkPayRequestData,
  PublicPaymentLinkData,
  SimpleErrorData,
} from "app/types";
import { messages } from "app/i18n";
import { FormattedMessage, useIntl } from "react-intl";
import { UnexpectedErrorState, PaymentLinkDetailsFormFields } from "app/components";
import { paymentLinksApiUrl } from "app/constants";
import { Avatar, Button, Flex, Stack, Text } from "@mollie/ui-react";
import { Formik, FormikHelpers } from "formik";
import {
  ExpectedErrorState,
  Footer,
} from "@mollie/revenue-collection-components";

export type PaymentLinkDetailsFormValues = {
  amount: number | string;
  currency: string;
  description: string;
  name: string;
  email: string;
};

enum Error {
  BadResponse = "Bad response from server",
  PaymentAmountTooHigh = "Payment amount is too high",
  PaymentAmountTooLow = "Payment amount is too low",
  NoSuitablePaymentMethods = "No suitable payment methods found for the given payment link",
}

export const PaymentLinkDetailsPage: FC = () => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);
  const params: Params<string> = useParams();
  const {
    state,
  }: {
    state: {
      paymentLinkData: PublicPaymentLinkData;
      autoPayData: AutoPayData | null;
      customerToken: string | null;
    };
  } = useLocation();
  const initialValues: PaymentLinkDetailsFormValues =
    useMemo<PaymentLinkDetailsFormValues>(
      () => ({
        amount: "",
        currency: "EUR",
        description: "",
        name: "",
        email: "",
      }),
      [],
    );
  const hasCustomerToken = state.paymentLinkData.sequenceType === "first" 
    && state.paymentLinkData.customerToken !== null 
    && state.paymentLinkData.customerTokenSource === 'merchant'
    && !state.paymentLinkData.isVariableAmount;

  const onSubmit = useCallback(
    async (
      values: PaymentLinkDetailsFormValues,
      formikHelpers: FormikHelpers<PaymentLinkDetailsFormValues>,
    ) => {
      const createCustomer = async (name: string, email: string, locale: string) => {
        const apiUrl = new URL(
          `api/bff/v1/payment-links/${params.paymentLinkHash}/customer`,
          paymentLinksApiUrl,
        );
        const body: CustomerRequestData = {name, email, locale};
    
        const response = await fetch(apiUrl, {
          method: !state?.customerToken ? "POST" : "PATCH",
          body: JSON.stringify(body),
        });
    
        return response.json();
      }

      let createdCustomer: CustomerData = {
        customerToken: null
      };

      if (values.name || values.email) {
        createdCustomer = await createCustomer(
          values.name, 
          values.email, 
          params?.lang || 'en'
        );

        if (!state?.customerToken && !createdCustomer?.customerToken) {
          setError(Error.BadResponse);
        }
      }

      const apiUrl = new URL(
        `api/bff/v1/payment-links/${params.paymentLinkHash}/pay`,
        paymentLinksApiUrl,
      );

      const body: PaymentLinkPayRequestData = {
        customerToken: state?.customerToken || createdCustomer?.customerToken || null
      };

      if (String(values.amount).length > 0) {
        body.amount = {
          value: String(values.amount),
          currency: values.currency,
        }
      }
      if (values.description) {
        body.customerMessage = values.description;
      }
      if (error === null) {
        fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (!response.ok) {
              formikHelpers.setSubmitting(false);
  
              if (response.status === 422) {
                return response
                  .json()
                  .then((data: ErrorData | SimpleErrorData) => {
                    if ((data as SimpleErrorData).error) {
                      const error = (data as SimpleErrorData).error as Error;
                      if (Object.values(Error).includes(error)) {
                        return setError(error);
                      }
                    }
                    if (
                      (data as ErrorData).detail &&
                      Object.values(Error).includes(
                        (data as ErrorData).detail as Error,
                      )
                    ) {
                      const errorKey = Object.keys(Error).find(
                        (key) =>
                          Error[key as keyof typeof Error] ===
                          (data as ErrorData).detail,
                      );
  
                      formikHelpers.setFieldError(
                        "amount",
                        intl.formatMessage(
                          messages[
                            `paymentLinkDetailsFormError${errorKey}` as keyof typeof messages
                          ],
                        ),
                      );
                    } else {
                      setError(Error.BadResponse);
                    }
                  });
              }
  
              return setError(Error.BadResponse);
            } else {
              response.json().then((data: PaymentLinkPayData) => {
                // deepcode ignore OR: this URL is supplied by the backend
                window.location.replace(data.redirectUrl);
              });
            }
          })
          .catch(() => {
            return setError(Error.BadResponse);
          });
      }
    },
    [intl, params.paymentLinkHash, state?.customerToken, params.lang],
  );

  if (error === Error.BadResponse) {
    return <UnexpectedErrorState />;
  }
  if (error === Error.NoSuitablePaymentMethods) {
    return (
      <ExpectedErrorState
        error={intl.formatMessage(
          messages.paymentLinkPaymentErrorNoSuitablePaymentMethods,
        )}
      />
    );
  }

  if (!state?.paymentLinkData || state?.customerToken === undefined) {
    return (
      <Navigate
        to={`/${params.lang}/payment/${params.paymentLinkHash}`}
        replace={true}
      />
    );
  }

  return (
    <Stack margin="auto" spacing="space-300" width="100%">
      <Flex gap="space-100" alignItems="flex-start" direction="row">
        {state.paymentLinkData.profileLogoUrl && (
          <Avatar
            variant="organisation"
            size="extra-large"
            src={state.paymentLinkData.profileLogoUrl}
            marginBlockEnd="space-50"
          />
        )}
        <Text variant="subtitle-medium" marginBlockStart="space-100">
          {state.paymentLinkData.profileName}
        </Text>
      </Flex>
      <Flex gap="space-100" alignItems="flex-start" direction="column">
        <Text variant="heading-title-4">
          {state.paymentLinkData.description}
        </Text>
        {state.paymentLinkData.sequenceType === "first" && (
          <Text variant="body-underline" color="disabled">
            <FormattedMessage
              {...messages.paymentLinkDetailsFormDisclaimer}
              values={{ organisation: state.paymentLinkData.profileName }}
            />
          </Text>
        )}
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        validateOnMount={false}
        isInitialValid={false}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <>
            <PaymentLinkDetailsFormFields
              availableCurrencies={state.paymentLinkData.availableCurrencies}
              autoPayData={state.autoPayData}
              paymentLinkData={state.paymentLinkData}
            />
            <Stack spacing="space-100">
              <Button
                variant="primary"
                width="100%"
                loading={isSubmitting}
                disabled={hasCustomerToken ? false : !isValid}
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <FormattedMessage
                  {...messages.paymentLinkDetailsFormContinueToCheckoutButton}
                />
              </Button>
              <Footer />
            </Stack>
          </>
        )}
      </Formik>
    </Stack>
  );
};
