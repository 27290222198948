export function extractCurrencyAndAmount(input: string): {
  currency: string;
  amount: string;
} {
  const currencyRegex = /^[A-Z]{3}/;
  const amountRegex = /[0-9]+(?:,[0-9]{1,3})?$/;

  const currencyMatch = input.match(currencyRegex);
  const amountMatch = input.match(amountRegex);

  const currency = currencyMatch ? currencyMatch[0] : "EUR";
  const amount = amountMatch ? amountMatch[0].replace(",", ".") : "";

  return { currency, amount };
}

/*
 * Regex from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
 *
 * The regex is slightly adapted to enforce a TLD.
 */

export const isValidEmail = (value: string): boolean => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/i.test(
  value,
);
