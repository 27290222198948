// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  'invoice-ar.invoice-landing-loading-message': 'Your Invoice payment options will appear soon.',
  'invoice-ar.invoice-landing-loading-title': 'Loading...',
  'invoice-ar.invoice-not-found-error': 'Invoice not found',
  'invoice-ar.invoice-paid-with-invoice-number': 'Invoice {invoiceNumber} is paid',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable': 'Invoice is not payable',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-description':
    'Please contact your supplier for details on the status of this invoice.',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-with-invoice-number':
    'Invoice {invoiceNumber} is not payable',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available':
    'Online payment not supported',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available-description':
    'Online payment not supported for this invoice. Please pay the invoice via bank transfer.',
  'invoice-ar.invoice-unexpected-error':
    'An unexpected error occurred, please try again later or contact support',
  'payment-links.payment-link-details-form-amount-label': 'Amount',
  'payment-links.payment-link-details-form-continue-to-checkout-button': 'Continue to checkout',
  'payment-links.payment-link-details-form-currency-label': 'Currency',
  'payment-links.payment-link-details-form-description-helper-text':
    'A note for the payment you’re sending',
  'payment-links.payment-link-details-form-description-label': 'Description',
  'payment-links.payment-link-details-form-disclaimer':
    'By paying, you allow {organisation} to charge you for future payments in accordance to their terms. You can always cancel your mandate.',
  'payment-links.payment-link-details-form-email-label': 'Email',
  'payment-links.payment-link-details-form-error-amount-positive': 'Amount must be positive',
  'payment-links.payment-link-details-form-error-invalid-email-address': 'Invalid email address',
  'payment-links.payment-link-details-form-error-invalid-name':
    'Name must be between 5 and 255 characters',
  'payment-links.payment-link-details-form-error-minimum-amount': 'The minimum amount is {amount}',
  'payment-links.payment-link-details-form-error-payment-amount-too-high': 'Amount is too high',
  'payment-links.payment-link-details-form-error-payment-amount-too-low': 'Amount is too low',
  'payment-links.payment-link-details-form-name-label': 'Name',
  'payment-links.payment-link-expired': 'This payment link has expired',
  'payment-links.payment-link-expired-description': 'Contact {profileName} to request a new link',
  'payment-links.payment-link-landing-loading-message':
    'Your Payment Link payment options will appear soon.',
  'payment-links.payment-link-landing-loading-title': 'Loading...',
  'payment-links.payment-link-not-found': 'Payment link not found',
  'payment-links.payment-link-paid': 'This payment link has been paid',
  'payment-links.payment-link-payment-error-no-suitable-payment-methods':
    'No suitable payment methods found for this payment link. Contact the supplier of your link to resolve this problem.',
  'payment-links.payment-link-payment-loading-message': 'Your payment status will appear soon.',
  'payment-links.payment-link-payment-paid-title':
    'Your {amount} payment to {profileName} succeeded',
  'payment-links.payment-link-payment-pending-description':
    'We’re awaiting your payment status. We’ll inform you once the payment has cleared.',
  'payment-links.payment-link-payment-pending-title':
    'Your {amount} payment to {profileName} is pending',
  'payment-links.payment-link-unexpected-error':
    'An unexpected error occurred, please try again later or contact support',
};
