import { FC, useCallback, useEffect } from "react";
import { ComboBox, Flex, TextField } from "@mollie/ui-react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { messages } from "app/i18n";
import { PaymentLinkDetailsFormValues } from "app/pages";
import { AutoPayData, Currency, PublicPaymentLinkData } from "app/types";
import { extractCurrencyAndAmount, isValidEmail } from "app/helpers";

type PaymentLinkDetailsFormFieldsProps = {
  availableCurrencies: Currency[];
  autoPayData: AutoPayData | null;
  paymentLinkData: PublicPaymentLinkData;
};

export const PaymentLinkDetailsFormFields: FC<PaymentLinkDetailsFormFieldsProps> = ({
  availableCurrencies,
  autoPayData,
  paymentLinkData,
}) => {
  const intl = useIntl();
  const { errors, setFieldValue, submitForm, values } =
    useFormikContext<PaymentLinkDetailsFormValues>();
  const isMultipleCurrency = availableCurrencies.length > 1;

  const getAmountStep = useCallback(
    (currencyCode: string) => {
      const currency = availableCurrencies.find(
        (currency) => currency.code === currencyCode,
      );

      return 1 / 10 ** (currency?.precision ?? 2);
    },
    [availableCurrencies],
  );

  useEffect(() => {
    const handleAutoPay = async () => {
      if (autoPayData?.amount) {
        const { currency, amount } = extractCurrencyAndAmount(
          autoPayData.amount,
        );
        await setFieldValue("currency", currency, false);
        await setFieldValue("amount", amount, false);
        if (autoPayData.message) {
          await setFieldValue("description", autoPayData.message, false);
        }

        if (amount != "") {
          // Timeout is needed to ensure the form is re-rendered before submit
          setTimeout(submitForm, 50);
        }
      }
    };

    handleAutoPay();
  }, [autoPayData, setFieldValue, submitForm]);

  return (
    <>
      {((paymentLinkData.sequenceType === "first" && paymentLinkData.customerToken === null) || (paymentLinkData.sequenceType === "first" && paymentLinkData.customerTokenSource === 'customer')) && (
        <>
        <Field
          name="name"
          validate={(value: string) => value.length < 5 || value.length > 255
            ? intl.formatMessage(
              messages.paymentLinkDetailsFormErrorInvalidName
            )
            : null}
        >
          {({ field }: FieldProps) => (
            <TextField
              {...field}
              label={intl.formatMessage(
                messages.paymentLinkDetailsFormNameLabel
              )}
              type="text"
              min={5}
              max={255}
              validationText={errors.name} 
            />
          )}
        </Field>
        <Field
          name="email"
          validate={(value: string) => !isValidEmail(value) || value.length > 255
            ? intl.formatMessage(
              messages.paymentLinkDetailsFormErrorInvalidEmailAddress
            )
            : null}
        >
          {({ field }: FieldProps) => (
            <TextField
              {...field}
              label={intl.formatMessage(
                messages.paymentLinkDetailsFormEmailLabel
              )}
              type="email"
              validationText={errors.email} />
          )}
        </Field>
        </>
      )}
      {paymentLinkData.isVariableAmount && (
        <>
        <Flex alignItems="flex-start">
          {isMultipleCurrency && (
            <Field name="currency">
              {({ field }: FieldProps) => (
                <ComboBox
                  {...field}
                  label={intl.formatMessage(
                    messages.paymentLinkDetailsFormCurrencyLabel
                  )}
                  width="25%"
                >
                  {availableCurrencies.map((currency) => (
                    <ComboBox.Item
                      key={currency.code}
                      value={currency.code}
                      onClick={(value) => {
                        setFieldValue("currency", value);
                      } }
                    >
                      {currency.code}
                    </ComboBox.Item>
                  ))}
                </ComboBox>
              )}
            </Field>
          )}
          <Field
            name="amount"
            validate={(value: number) => {
              if (value <= 0) {
                return intl.formatMessage(
                  messages.paymentLinkDetailsFormErrorAmountPositive
                );
              }

              if (value < getAmountStep(values.currency)) {
                return intl.formatMessage(
                  messages.paymentLinkDetailsFormErrorPaymentAmountTooLow
                );
              }
              
              if (paymentLinkData?.minimumAmount && value < Number.parseFloat(paymentLinkData.minimumAmount.value)) {
                return intl.formatMessage(
                  messages.paymentLinkDetailsFormErrorMinimumAmount,
                  {
                    amount: intl.formatNumber(
                      Number.parseFloat(paymentLinkData.minimumAmount.value)
                    ),
                  }
                );

              }
              return null;
            }}
          >
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                width={isMultipleCurrency ? "75%" : "100%"}
                type="number"
                label={intl.formatMessage(
                  messages.paymentLinkDetailsFormAmountLabel
                )}
                min={0}
                step={getAmountStep(values.currency)}
                prefix={isMultipleCurrency ? "" : availableCurrencies[0].code}
                validationText={errors.amount}
                placeholder={intl.formatNumber(getAmountStep(values.currency))}
                autoFocus={true}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    submitForm();
                  }
                } } 
              />
            )}
          </Field>
        </Flex>
        <Field name="description">
          {({ field }: FieldProps) => (
            <TextField
              {...field}
              label={intl.formatMessage(
                messages.paymentLinkDetailsFormDescriptionLabel
              )}
              helperText={intl.formatMessage(
                messages.paymentLinkDetailsFormDescriptionHelperText
              )}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  submitForm();
                }
              } } />
          )}
        </Field>
        </>
      )}
    </>
  );
};
